<template>
  <base-section
    id="experience-capabilities"
  >
    <base-section-heading :title="title">
      <h3>
        {{subheading}}
      </h3>
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(experience, i) in experiences"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="experience"
            align="left"
            horizontal
            color="primary"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionExperienceCapabilities',
    created () {
      this.experiences[0].title = this.$t('capabilities.commitment')
      this.experiences[1].title = this.$t('capabilities.range')
      this.experiences[2].title = this.$t('capabilities.international')
      this.experiences[3].title = this.$t('capabilities.innovation')
      this.experiences[4].title = this.$t('capabilities.pricing')
      this.experiences[5].title = this.$t('capabilities.quality')
      this.experiences[0].text = this.$t('capabilities.commitment_text')
      this.experiences[1].text = this.$t('capabilities.range_text')
      this.experiences[2].text = this.$t('capabilities.international_text')
      this.experiences[3].text = this.$t('capabilities.innovation_text')
      this.experiences[4].text = this.$t('capabilities.pricing_text')
      this.experiences[5].text = this.$t('capabilities.quality_text')

      this.$EventBus.$on('localeChanged', () => {
        this.experiences[0].title = this.$t('capabilities.commitment')
        this.experiences[1].title = this.$t('capabilities.range')
        this.experiences[2].title = this.$t('capabilities.international')
        this.experiences[3].title = this.$t('capabilities.innovation')
        this.experiences[4].title = this.$t('capabilities.pricing')
        this.experiences[5].title = this.$t('capabilities.quality')
        this.experiences[0].text = this.$t('capabilities.commitment_text')
        this.experiences[1].text = this.$t('capabilities.range_text')
        this.experiences[2].text = this.$t('capabilities.international_text')
        this.experiences[3].text = this.$t('capabilities.innovation_text')
        this.experiences[4].text = this.$t('capabilities.pricing_text')
        this.experiences[5].text = this.$t('capabilities.quality_text')
      })
    },
    data: () => ({
      experiences: [
        {
          title: 'our commitment',
          icon: 'mdi-calendar',
          dark: true,
          color: '#2f3030',
          text: 'We are committed to providing our business partners high quality products that cater to their growing industry needs and standards.',
        },
        {
          title: 'wide range of chemical products',
          icon: 'mdi-factory',
          dark: true,
          color: '#2f3030',
          text: 'Supporting diversified industries with our one-stop chemical solution.',
        },
        {
          color: '#2f3030',
          dark: true,
          title: 'International distribution & trading',
          icon: 'mdi-earth',
          text: 'We manage international distribution and trading for a diverse number of industries.',
        },
        {
          title: 'Innovation & Value creation',
          icon: 'mdi-flask-empty-outline',
          dark: true,
          color: '#2f3030',
          text: 'LEE HENG GLOBAL Co,. Ltd. anticipates the needs of the industries through constant innovation and value creation, by working closely with our manufacturers and clients to deliver the best products and solutions for the industries. ',
        },
        {
          title: 'competitive pricing',
          icon: 'mdi-certificate',
          dark: true,
          color: '#2f3030',
          text: 'Our competitive prices allow you to stand-out from the crowd.',
        },
        {
          title: 'high quality consistent performance',
          icon: 'mdi-hand-back-right-outline',
          dark: true,
          color: '#2f3030',
          text: 'High quality products ensures your product performance. Tested reliability and assured consistency.',
        },
      ],
    }),
    computed: {
      subheading () {
        return this.$t('capabilities.subheading')
      },
      title () {
        return this.$t('capabilities.title')
      },
      body () {
        return this.$t('capabilities.body')
      },
      discover () {
        return this.$t('capabilities.discover')
      },
    },
  }
</script>
